<template>
  <div>
    <div>
      <el-table :data="delList" border style="width: 100%">
        <el-table-column
          prop="cname"
          label="患者姓名"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="sex" label="性别" align="center" width="150">
        </el-table-column>
        <el-table-column
          prop="age"
          label="年龄"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column label="联系电话" align="center" width="220">
          <template slot-scope="scope">{{
            scope.row.phone | hideMiddle
          }}</template>
        </el-table-column>
        <el-table-column label="身份证号" align="center" width="220"
          ><template slot-scope="scope">{{
            scope.row.cid | hideMiddle
          }}</template>
        </el-table-column>
        <el-table-column
          prop="history"
          label="既往病史"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column label="就诊卡号" align="center" width="150"
          ><template slot-scope="scope">{{
            scope.row.hospitalId | hideMiddle
          }}</template></el-table-column
        >
        <el-table-column prop="address" label="地址" align="center" width="150">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-refresh-left"
              size="mini"
              @click="recoverys(scope.row.cid)"
              >恢复</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="del(scope.row.id)"
              >永久删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Page2></Page2>
  </div>
</template>
<script>
import { Message } from "element-ui";
import Page2 from "./Page2.vue";
import { createNamespacedHelpers } from "vuex";
// import axios from "../http/axios";
import api from "../http/api";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "patient"
);
export default {
  components: {
    Page2,
  },
  created() {
    this.getDelList();
  },

  filters: {
    hideMiddle(val) {
      return `${val.substring(0, 3)}****${val.substring(val.length - 3)}`;
    },
  },
  computed: {
    ...mapState(["delList", "updates", "pageSize", "currentpage"]),
  },
  methods: {
    ...mapMutations([
      "changePageData",
      "changeSearchData",
      "changePageSize",
      "changeCurrentpage",
    ]),
    ...mapActions([
      "updateCal",
      "recovery",
      "remove",
      "updateReport",
      "getDelList",
    ]),

    //   点击修改的弹出框方法
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 进入对应患者的可视化数据页面
    gotoEchats(cid) {
      //  this.$router.push(`/Correspondence_Report/${cname}/${id}`);
      this.$router.push(`/system/lookEchart/${cid}`);
    },
    // 获取某一个患者数据
    async getPatientOne(cid) {
      this.dialogVisible = true;
      const data = await api.patient.search(cid);
      if (data.status == 200) {
        this.update = data.data.data.records[0];
        // console.log("获取某一个数据：", this.update);
      }
    },

    // 确定修改
    updateBtn() {
      this.updateCal(this.update);
      Message.success("修改成功");
      this.getPatient();
      this.dialogVisible = false;
    },
    noUpdate() {
      this.dialogVisible = false;
      Message.info("已取消修改");
    },
    // 删除患者
    recoverys(cid) {
      // console.log("id", id);
      this.getDelList();
      // 删除提示框
      this.$confirm("此操作将恢复该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "恢复成功！",
          });
          this.recovery(cid);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消恢复",
          });
        });
    },
    del(id) {
      this.getDelList();
      // 删除提示框
      this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功！",
          });
          this.remove(id);
          this.getDelList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            
          });
        });
    },
  },

  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      searchData: [],
      //   delList: [],
      findKey: "",
      // handleClose: false,
      input: "",
      name: "",
      // 存放修改患者数据
      update: {},
      // 存放修改报告数据
      update2: {},
    };
  },
};
</script>
<style lang="scss" scoped>
.item {
  margin: 10px;
}
.cell {
  text-align: center;
}
#searcheBox {
  width: 200px;
}
.el-input {
  width: 200px;
}
</style>
